@import '../../style/_base/all';

.wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 99;
    // color: $grey00;
    color: #fff;
    overflow: auto;
    :global(select[name="product_id"]){
        z-index:5;
    }
    :global(svg){
        fill: #FFF;
    }
    :global(.rdtPicker){
        color: #000;
        -webkit-box-shadow: 2px 2px 12px 6px rgba(0,0,0,0.2); 
        box-shadow: 2px 2px 12px 6px rgba(0,0,0,0.2);
        :global(svg){
            fill: #000;
        }
    }
    
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: calc(100% - #{rem(32)});
        padding: rem(16) 0;
        @include bp(desktop) {
            padding: rem(60) 0;
        }
    }

    .inner {
        display: flex;
        position: relative;
        /*     background-color: $white;
        background-image: url('/imgs/receipts/bg.jpg');
     */
        background-color: $corporate;
        background-size: cover;
        width: 95%;
        box-shadow: 1px 1px 6px rgba($black, 0.3);
        border-radius: $border-radius;
        overflow: hidden;
        @include bp(tablet) {
            max-width: rem(450);
        }

        .close {
            cursor: pointer;
            position: absolute;
            right: rem(25);
            top: rem(15);
            width: rem(15);
            i {
                display: block;
                width: 100%;
                svg{
                    fill: black;
                }
            }
        }
    }

    .section {
        width: 100%;
        color: black;
        padding: rem(20);
        text-align: center;
        color: #fff;
        padding-top: 20px;
        .title{
            font-weight: bold;
            padding: 15px 0px;
            font-size: 17px;
        }
        .description{
            padding-bottom: 15px;
            line-height: 1.5;
        }
        #gigya-form-container{
            background-color: #fff !important;
        }
    }

}

:global {
    .popup-enter {
        opacity: 0;
        transform: translateY(50px);
    }
    .popup-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: 0.25s;
    }
    .popup-exit {
        opacity: 1;
    }
    .popup-exit-active {
        opacity: 0;
        transition: 0.25s;
        transform: translateY(50px);
    }
    select[name='product_id'],
    input#amount,
    input#nscontrino,
    input.inputScontrino {
        width: 231px;
        padding: 10px;
        background: transparent;
        border: 2px dashed #e1e1ec;
        margin: 10px;
        border-radius: 6px;
        color: #FFF;
    }
    select[name='product_id'],
    input::placeholder {
        color: $white;
    }

    select[name='product_id'] option{
        color: $white;
        background-color: $grey01;
    }
}

:global(.fully-hidden){
    display: none;
}

