@function c($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }
  @warn 'There is no item "#{$key}" in this list; choose one of: #{$colors}';
  @return null;
}

// layout  //////////////////////////////////////////////////
.custom_row {
  display: block;
  float: none;
  clear: both;
  font-size: 0;
  footer.footer & {
    max-width: 100%;
  }
  @include respond-to("viewport:desktop") {
    // @include layout($susy-desktop);
    // @include container($susy-desktop);
  }
  &--mobile-reverse {
    @include respond-to("viewport:mobile") {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  &--tablet-reverse {
    @include respond-to("viewport:tablet") {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  &--desktop-reverse {
    @include respond-to("viewport:desktop") {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .stickyDiv {
    &:before, &:after {
      content: '';
      display: table;
    }
    &--test {
      width: 300px;
      height: 250px;
      margin: auto;
      background-color: #9af589;
      font-size: 12px;
    }
  }
}

.col {

  //after glazed and bootstrap it need a fix
  font-size: 10px;
  line-height: 1;
  //after glazed and bootstrap it need a fix


  &--gallery-container {
    overflow: visible;
    padding: 2px 0 10px;
    @include respond-to("viewport:mobile") {
      overflow: hidden;
      padding: 2px 20px 10px;
    }
    @include respond-to("viewport:min-tablet") {
      overflow: hidden;
      transform: none !important;
      padding: 0 20px;
    }
    @include respond-to("viewport:min-tablet") {
      padding: 0;
    }
  }

  &--fourcards-container {
    overflow: visible;
    padding: 2px 0 10px;
    @include respond-to("viewport:mobile") {
      overflow: hidden;
      padding: 2px 20px 10px;
    }
    @include respond-to("viewport:min-tablet") {
      overflow: hidden;
      padding: 2px 30px 10px;
    }
    @media (min-width: 769px) {
      padding: 0;
    }
  }

  &--saved-container {
    overflow: visible;
    padding: 2px 0 10px;
    &-wide {
      @include respond-to("viewport:tablet") {
        z-index: 0;
        padding-left: 30px;
        padding-right: 30px;
      }
      @include respond-to("viewport:mobile") {
        z-index: 0;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  &--filters-container {
    position: relative;
    z-index: 2;
    overflow: visible;
    padding: 0 2px 4px;
    @include respond-to("viewport:mobile") {
      overflow: hidden;
      padding: 2px 2px 10px;
      .search-box--inbody__container--filters & {
        padding: 0;
        margin: 0 2px;
      }
    }
    @include respond-to("viewport:tablet") {
      .search-box--inbody__container--filters & {
        width: 607px;
        margin: 0;
        padding: 0;
      }
    }
    @include respond-to("viewport:desktop") {
      .search-box--inbody__container--filters & {
        width: 568px;
        margin: 0;
        padding: 0;
      }
    }
    @include respond-to("viewport:min-tablet") {
      overflow: hidden;
      padding: 2px 2px 10px;
    }
    @include respond-to("viewport:min-tablet") {
      overflow: hidden;
      padding: 2px 2px 10px;
    }
  }

  &--filters-results-container {
    position: relative;
    z-index: 1;
    margin-top: -10px;
    .search-box--inbody__container--listing-ricettario & {
      margin-top: 0;
      max-width: 568px;
    }
    font-size: 0;
    text-align: center;
  }

  &--main {
    @include respond-to("viewport:desktop") {
      display: inline-block;
      vertical-align: top;
      font-size: 0;
      // @include layout($susy-desktop);
      // width: span(6);
    }

    &--left {
      @include respond-to("viewport:desktop") {
        // @include layout($susy-desktop);
        // margin-right: gutter();
      }
    }

    &--right {
      @include respond-to("viewport:desktop") {
        // @include layout($susy-desktop);
        // margin-left: gutter();
      }
    }

    &--centered {
      @include respond-to("viewport:desktop") {
        max-width: 810px;
        margin: auto;
        padding: 0 20px;
        display: block;
      }
    }

    &--center {
      @include respond-to("viewport:desktop") {
        margin: auto;
        display: block;
        // @include layout($susy-desktop);
        // width: span(9);
      }
    }

    &--article {
      @include respond-to("viewport:mobile") {
        padding: 0;
      }
      @include respond-to("viewport:tablet") {
        padding: 0;
      }
      @include respond-to("viewport:desktop") {
        // @include layout($susy-desktop);
        // padding-right: span(1);
      }

      &--full-on-mobile {
        @include respond-to("viewport:mobile") {
          padding: 0;
        }
      }

      &--full-on-tablet {
        @include respond-to("viewport:tablet") {
          padding: 0;
        }
      }
    }

    &--comment-wrapper {
      @include respond-to("viewport:mobile") {
        margin-left: 20px;
        margin-right: 20px;
      }
      @include respond-to("viewport:tablet") {
        margin-left: 30px;
        margin-right: 30px;
      }
      @include respond-to("viewport:desktop") {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }

  &--side {
    display: inline-block;
    vertical-align: top;
    font-size: 0;
    @include respond-to("viewport:desktop") {
      // @include layout($susy-desktop);
      // width: span(3);
    }

    &--left {
      @include respond-to("viewport:desktop") {
        // @include layout($susy-desktop);
        // margin-right: gutter();
      }
    }

    &--article {
      @include respond-to("viewport:mobile") {
        display: block;
      }
      @include respond-to("viewport:tablet") {
        display: block;
      }
    }

    &--area {
      @include respond-to("viewport:mobile") {
        display: block;
      }
      @include respond-to("viewport:tablet") {
        display: block;
      }
    }
  }
}

.link-icon {
  display: block;
  width: 17px;
  height: 17px;
  right: 0;
  top: 56%;

  svg {
  }
}

.icon {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;

  svg {
  }
}

.fbButton {
  color: $white;
  background-color: $facebookblue;
  height: 40px;
  display: inline-block;
  border-radius: $border-radius-sm;
  padding: 7px;
  cursor: pointer;
  @include respond-to("viewport:mobile") {
    .visual__captiontext & {
      margin: 20px auto 0;
    }
  }
  &:hover{
    color: $white;
  }
  svg {
    width: 36px;
    height: 26px;
    fill: currentColor;
    display: inline-block;
    .registrazione--button-list & {
      width: 38px;
      height: 33px;
    }
  }
  span {
    display: inline-block;
    font-weight: 500;
    font-size: 15px;
    margin: 4px 7px;
    text-align: center;
    vertical-align: top;
    align-items: center;
    @include respond-to("viewport:mobile") {
      font-size: 13px;
    }
    .registrazione--button-list & {
      margin: 7px 18px;
      @include respond-to("viewport:mobile") {
        margin: 6px 12px;
        font-size: 18px;
      }
    }
  }
  .registrazione--button-list & {
    height: 54px;
    padding: 10px 12px;
    margin-right: 25px;
    width: 263px;
    text-align: left;
    @include respond-to("viewport:mobile") {
      margin: 0 0 30px 0;
    }
  }
}

.dropdown {
  &--container {
    max-height: 0;
    overflow: hidden;
    position: absolute;
    top: 42px;
    background-color: $white;
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
    right: 20px;
    visibility: hidden;
    z-index: 5;
    .area--menu-mobile & {
      top: 32px;
    }
    &.showed {
      visibility: visible;
      max-height: 167px;
      overflow-y: scroll;
    }
    @include respond-to("viewport:desktop") {
      right: 0
    }
    @include respond-to("viewport:tablet") {
      right: 30px;
    }
  }
  &--select {
    font-size: 14px;
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 165px;
    background-color: $white;
    li {
      &:first-child { border: none;}
      transition: all 0.2s linear;
      cursor: pointer;
      padding: 10px 0;
      text-align: center;
      border-top: 1px solid $grey;
      &:hover, &.active {
        background-color: $corporate-dark;
        a {
          font-weight: 700;
          color: $white;
        }
      }
      a {
        color: $grey;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.1px;
      }
    }
  }
}

iframe { border: 0; }

.coupon-list { padding: 0; }
