@import '../../style/rs22/vars';

$t: (
        body: (
                font-family: $font-sans-serif,
                font-size : $font-size-normal,
                font-weight: $font-weight-normal
        ),
        link: (
                text-transform: uppercase,
                font-family: $font-sans-serif,
                font-size : $font-size-normal,
                font-weight: $font-weight-normal,
                text-decoration: underline,
                letter-spacing: .5px
        ),
        h6: (
                font-family: $font-sans-serif,
                font-size : $font-size-base,
                font-weight: $font-weight-bold
        ),
        h5: (
                font-family: $font-sans-serif,
                font-size : $font-size-s,
                font-weight: $font-weight-bold
        ),
        h4: (
                font-family: $font-sans-serif,
                font-size : $font-size-m,
                font-weight: $font-weight-bold
        ),
        h3: (
                font-family: $font-sans-serif,
                font-size : $font-size-l,
                font-weight: $font-weight-bold
        ),
        h2: (
                font-family: $font-sans-serif,
                font-size : $font-size-xl,
                font-weight: $font-weight-bold,
        ),
        h1: (
                font-family: $font-serif,
                font-size : $font-size-xxl,
        ),
        caption: (
                font-family: $font-sans-serif,
                font-size : $font-size-normal,
                font-weight: $font-weight-bold
        ),
        small: (
                font-family: $font-sans-serif,
                font-size : $font-size-normal,
                font-weight: $font-weight-bold
        ),
        text: (
                font-family: $font-sans-serif,
                font-size : $font-size-normal,
                font-weight: $font-weight-normal
        ),
        bigTitle: (
                font-family: $font-serif,
                font-size : $font-size-l,
        ),
        printTitle : (
                font-family: $font-sans-serif,
                font-size : $font-size-l,
                text-transform : uppercase,
                font-weight: $font-weight-bold
        ),
        loginCaption: (
        text-transform: uppercase,
        font-family: $font-sans-serif,
        font-size : $font-size-xs,
        font-weight: $font-weight-normal,
        text-decoration: underline,
        letter-spacing: .5px
    ),
);


@mixin text($style) {
  @each $key, $attr in map-get($t, #{$style}) {
    @if $key == 'mq' {
      @each $mq_key, $breakpoint in map-get(map-get($t, #{$style}), mq) {
        @include bp(#{$mq_key}) {
          @each $bp_key, $bp_attr in $breakpoint {
              #{$bp_key} : #{$bp_attr};
          }
        }
      }
    } @else {
        #{$key} : #{$attr};
    }
  }
}
