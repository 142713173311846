.gigya-style-modern {
    *,
    .gigya-screen-dialog-caption {
        font-family: $font-sans-serif !important;
    }

    input[type='email'],
    input[type='password'],
    input[type='text'],
    select,
    textarea {
        border-radius: 0 !important;
        font-size: rem(16) !important;
        padding-left: rem(12);
        @media screen and (min-width: 10rem) {
            font-size: rem(13) !important;
        }
    }

    .gigya-screen-dialog-main {
        *,
        .gigya-screen-dialog-caption {
            font-family: $font-sans-serif;
        }

        div.gigya-screen-dialog-top {
            background-color: $corporate;
            padding: 10px;
        }

        .gigya-screen input[type='submit'] {
            background-color: $corporate;
            border-radius: $border-radius-lg;
            font-size: 14px;
            font-weight: 700;
        }

        .gigya-screen a,
        .gigya-screen a:active,
        .gigya-screen a:link,
        .gigya-screen a:visited {
            color: $black;
        }

        .gigya-screen a > .gigya-button,
        .gigya-composite-control-link input[type='button'] {
            background-color: $corporate;
            color: $white !important;
            margin-top: 16px;
            font-size: 16px;
            border-radius: $border-radius-lg;
        }

        .gigya-social-login-container {
            height: 50px !important;
        }

        div.gigya-screen-dialog-caption {
            @include bp(tablet) {
                padding-left: 10px;
            }
        }

        div.gigya-screen-dialog-close {
            a {
                background-image: url('/imgs/close.svg');
                display: block;
                width: 16px;
                height: 16px;
                margin-top: 10px;

                img {
                    display: none;
                }
            }
            @include bp(tablet) {
                padding-right: 10px;
            }
        }

        .gigya-composite-control {
            font-size: 12px;
            line-height: 1.2;
            margin-bottom: 10px;

            &.gigya-composite-control-header {
                font-size: 14px;
            }

            &.gigya-composite-control-submit {
                text-align: center;
                margin-top: 12px !important;
            }

            &.gigya-composite-control-link.cta {
                font-size: 16px;
                font-weight: 700;
                text-decoration: underline;
            }

            .gigya-input-text,
            .gigya-input-password {
                border-radius: $border-radius !important;
            }
        }

        label.gigya-composite-control.gigya-composite-control-label.subtitle {
            margin: 0;
        }
    }

    &.gigya-screen-dialog-mobile {
        .gigya-screen-dialog-main {
            .gigya-login-providers-container {
                transform: translate(-22px, -50%);
            }

            div.gigya-screen-dialog-caption {
                text-transform: uppercase;
                font-weight: bold;
            }

            div.gigya-screen-dialog-inner {
                margin-top: 45px;
            }

            div.gigya-screen-dialog-close {
                a {
                    margin-top: 5px;
                }
            }
        }
    }
}

div#gigNotifications {
    position: fixed;
    left: 0;
    top: 0;
    right: 0 !important;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @include bp(tablet) {
        background-color: rgba($white, 0.8);
        backdrop-filter: blur(3px);
    }

    #gigNotifications_notification {
        height: 100%;
        overflow: hidden;
        transition: none !important;
        top: 0 !important;

        @include bp(tablet) {
            height: auto;
        }
    }

    &.-active {
        .gigya-notification-levelup {
            transform: scale(1);
            opacity: 1;
        }
    }

    .gigya-notification-levelup * {
        font-family: $font-sans-serif;
        text-align: center;
        color: $white;
    }

    .gigya-notification-levelup {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        background-image: url('/imgs/texture/loyalty.jpg');
        background-size: cover;
        background-position: center;
        text-align: center;
        padding: rem(32) rem(48);
        transform: scale(0.95);
        opacity: 0;
        transition: all 0.55s ease;

        @include bp(tablet) {
            width: rem(490);
            border-radius: $border-radius;
            transition: all 0.35s ease;
        }

        &__wrapper {
            width: 100%;
        }

        &__close {
            position: absolute;
            right: rem(24);
            top: rem(22);
            width: rem(22);
            height: rem(22);

            @include bp(tablet) {
                right: rem(18);
                top: rem(18);
                width: rem(18);
                height: rem(18);
            }

            svg {
                fill: $white;
            }
        }

        &__image {
            img {
                max-width: rem(210);
                margin: rem(26) auto rem(12);
            }
        }

        &__head {
            @include text(h1);
            margin-bottom: rem(12);
        }

        &__text,
        &__title {
            @include text(text);

            &.-info {
                font-size: rem(10);
            }
        }

        &__title {
            font-size: rem(18);
            font-weight: 800;
        }

        &__cta {
            border-radius: $border-radius-lg;
            padding: rem(12) rem(16);
            text-align: center;
            display: inline-block;
            text-transform: uppercase;
            cursor: pointer;
            position: relative;
            background-color: $white;
            width: 100%;
            max-width: rem(280);
            margin-top: rem(28);

            @include bp(tablet) {
                padding: rem(15) rem(20);
            }

            > span {
                @include text(caption);

                font-weight: 800;
                width: 100%;
                color: $black;
            }
        }
    }

    .gig-notifications-closeImage {
        background-color: transparent;
        width: rem(22);
        height: rem(22);
        transform: translateY(-25px);

        @include bp(tablet) {
            width: rem(18);
            height: rem(18);
            transform: translateY(-21px);
        }
    }
}

.gigya-screen-content div.gigya-screen.landscape {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}
