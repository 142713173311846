@import './style/_base/presets';
@import './style/_base/all';
@import './style/_base/helpers';
@import './style/gigya';

html {
    font-size: 10px;
}

body {
    @include text(body);
    &.lock {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: calc(100vh - var(--vh-offset, 0px));
        overflow: hidden;
    }
    scroll-behavior: smooth;
}

i {
    font-style: italic;
}

b,
strong {
    font-weight: 700;
}

.content {
    @include blockPadding();

    max-width: rem(1180);
    margin: auto;
}

.wrap-hidden {
    overflow: hidden;
}

.switch-wrapper {
    position: relative;
}

.switch-wrapper > div {
    position: absolute;
    width: 100%;
    padding-top: 20px;
}

.-lazy {
    opacity: 0;
    transition: opacity 0.2s;
    &.-loaded {
        opacity: 1;
    }
}

#DotnAd_mh_u:empty {
    padding-bottom: 30%;
    background-color: $grey03;
}

.maintenance-block {
    text-align: center;
    padding: rem(32);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: rem(18);
    line-height: 1.4;

    svg {
        width: rem(60);
        margin-bottom: rem(24);
    }
}