$options: (
        "base-fontsize": 10px
);
$playfair: 'Playfair Display', Georgia, "Times New Roman", Times, serif;
$poppins: 'Poppins', Arial, Helvetica, sans-serif;

$fonts: (
        'arial': $poppins,
        'roboto': $poppins,
        'unna': $playfair,
        'raleway': $poppins,
        'selfie': $poppins,
);

// Colors
$black: #000000;
$almost-black: #010202;
$grey00: #373741;
$grey01: #535368;
$grey02: #9292A6;
$grey03: #E1E1EC;
$grey04: #F3F3F8;
$grey05: #F8F8FA;
$white: #FFFFFF;
$grey: $grey03;
$border: $grey03;

$corporate-light: #FF0A1B;
$yellow: #FDC600;
$corporate: #E1000F;
$loyaltypink: #D86E9E;
$loyaltyred: #D13143;
$mint: #CCE9D5;
$corporate-dark: #B8000C;
$grey_green: #8EA294;
$green: #69873F;
$acqua: #5B9E92;
$facebookblue: #3B5998;
$facebook: #1877F2;
$cyan: #17A2B8;
$blue: #005FBE;


$border-width:                1px;
$border-style:                solid;
$border-color:                $grey03;
$border_style:                $border-width $border-style $border-color;

$border-radius:               1rem;
$border-radius-lg:            2rem;
$border-radius-sm:            0.5rem;


// Layout
$zLayout: (
        "r_overlay",
        "menu",
        "search",
        "gallery-btns",
        "gallery",
        "header",
        "header-flash-messages",
        "header-top-row",
        "header-drawer",
        "gallery-inbody-pagination",
        "share-actions",
        "share-actions__submenu",
        "contents",
        "footer",
);

// Susy Grid
$susy-desktop: (
        columns: 9,
        gutters: 1.3/10,
        math: fluid,
        container: 1280px,
        container-position: center
);

$susy-mobile: (
        columns: 6,
        gutters: 1/2,
        math: fluid,
        container: auto,
        container-position: 20px
);

$susy-tablet: (
        columns: 9,
        gutters: 1/3,
        math: fluid,
        container: auto,
        container-position: 30px
);

$susy-tablet-internal: (
        columns: 7,
        gutters: 1/3,
        math: fluid,
        container: 940px,
        container-position: center
);

$shorthand: 9 1/4 fluid float inside;

// Breakpoints
$min-mobile: 320px;
$mid-mobile: 360px;
$max-mobile: 767px;
$min-tablet: 768px;
$max-tablet: 1319px;
$old-desktop: 1024px;
$min-desktop: 1320px;
$gigya-portrait-to: 919px;
$gigya-landscape-from: 920px;

//0-767
@include add-breakpoint("viewport:mobile", (max-width $max-mobile));

//768-1279 tablet
@include add-breakpoint("viewport:min-tablet", (min-width $min-tablet));
@include add-breakpoint("viewport:max-tablet", (max-width $max-tablet));
@include add-breakpoint("viewport:tablet", ($min-tablet $max-tablet));

//1280+ desktop
@include add-breakpoint("viewport:desktop", (min-width $min-desktop));

@include add-breakpoint("viewport:gigya-portrait", (max-width $gigya-portrait-to));
@include add-breakpoint("viewport:gigya-landscape", (min-width $gigya-landscape-from));

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin retina3x {
  @media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3),
  only screen and (min-resolution: 3dppx) {
    @content;
  }
}

@mixin ie-until10 {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

// @include add-breakpoint("device:mobile", ((max-device-width $max-mobile) (orientation portrait), (max-device-width 736px) (orientation landscape)));
// @include add-breakpoint("device:mobile-landscape", ((max-device-width $min-tablet) (orientation portrait), (max-device-width 736px) (orientation landscape)));
// @include add-breakpoint("device:desktop", ((min-device-width 769px) (orientation portrait), (min-device-width 737px) (orientation landscape)));


//LOYALTY // it's so hard to be humble
$loyalty-margin-mobile: 20px;
$loyalty-margin-tablet: 20px;

//Navbar
$navbar-height-mobile: 70px;
$navbar-height-desktop: $navbar-height-mobile * 2;

//Typography
$font-serif: $playfair;
$font-sans-serif: $poppins;

$font-size-base: 16px;
$font-size-normal: $font-size-base;
$font-size-xs: $font-size-base * 0.75;
$font-size-s: $font-size-base * 0.875;
$font-size-m: $font-size-base * 1.25;
$font-size-l: $font-size-base * 1.5;
$font-size-xl: $font-size-base * 2;
$font-size-xxl: $font-size-base * 3;
$font-size-display-1: $font-size-base * 3.5;
$font-size-display-2: $font-size-base * 4;
$font-size-display-3: $font-size-base * 4.5;
$font-size-display-4: $font-size-base * 5;

$font-size-article-index-heading: $font-size-base * 2.5;
$font-size-article-index-heading-mobile: $font-size-base * 1.75;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-strong: 600;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$line-height-base: 1;

// Spacers
$spacer: $font-size-base;

// Modal Share Article e Ricetta
$modal-share-max-width: 360px;
$modal-share-min-width: 300px;
$modal-share-border-radius: $border-radius-sm;
$modal-share-title: $font-size-article-index-heading;


//Swiper
$pagination-bullet-active-width: 40px;
$pagination-bullet-active-border-radius: $border-radius-sm;


//PLP Primo Livello
$header-title: $font-size-article-index-heading;
$card-big-border-radius: $modal-share-border-radius * 2;
$promoted-article-container: 950px;
$promoted-article-max-width: 890px;

//Tag List
$tag-border-radius: $border-radius-lg;
$tag-padding: 8px;

$ingredients-list-item-bullet: 7px;


//Icons
$icon-width-xl: 64px;
$icon-width-lg: 48px;
$icon-width-base: $spacer;

//Footer
$footer-logo-min-height: 55px;

//Buttons
$button-border-radius: $border-radius-lg;

//Search Form
$search-form-hp-height: 300px;

//Adv
$sticky-adv-height-half: 700px;
$sticky-adv-height-mpu: $search-form-hp-height;

//Logo
$logo-width-mobile: 170px;
$logo-height-mobile: 40px;

$logo-width-desktop: 290px;
$logo-height-desktop: 50px;
