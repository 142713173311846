#gigya-form-container .gigya-layout-row{
    padding-bottom: 20px !important;
}
#gigya-form-container .gigya-composite-control-multi-choice{
    display: flex;
    justify-content: flex-start;    
}
#gigya-form-container .gigya-multi-choice-item{
    width: 50% !important;
}

#gigya-form-container>.gigya-screen-content>.gigya-screen{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#gigya-form-container .gigya-style-modern .gigya-screen input[type='submit']{
    background-color: #FFF !important;
    color: #000 !important;
}

#gigya-form-container .disabled{
    pointer-events: none !important;
    opacity: 0.5 !important;
}

#gigya-form-container .gigya-style-modern .gigya-screen * label{
    color: #FFF !important;
}

#gigya-form-container .gigya-style-modern .gigya-screen * label span{
    color: #FFF !important;
}

#gigya-form-container .gigya-style-modern .gigya-screen * ul li{
    color: #FFF !important;
}

#gigya-form-container .gigya-style-modern .gigya-screen .gigya-error-msg {
    color: #FFF !important;
}

#gigya-form-container>.gigya-screen-content>.gigya-screen div.gigya-container.gigya-visible-when .gigya-composite-control{
    display: flex !important;
    justify-content: center !important;
}


#gigya-form-container>.gigya-screen-content>.gigya-screen input[type="submit"]{
    background-color: #FFF !important;
    color: #000 !important;
}


#gigya-form-container>.gigya-screen-content>.gigya-screen .gigya-label-text{
    font-size: 1.5rem !important;
}