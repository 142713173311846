@function rem($pxval) {
    @if not unitless($pxval) {
        $pxval: strip-units($pxval);
    }
    @return ($pxval / $baseFontSize) * 1rem;
}

@function color($color) {
    @return map-get($colors, $color);
}

@mixin bp($m) {
    @include breakpoint(map-get($mq, $m)) {
        @content;
    }
}

@mixin imagePadding($width, $height, $percentWidth: 100) {
    height: 0;
    padding-bottom: percentage($height / $width) * $percentWidth/100;
    position: relative;
    display: block;
    > img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
    }
}

@mixin hover() {
    .no-touch & {
        &:hover {
            @content;
        }
    }
}

@mixin blockPadding() {
    padding: 0 rem(18);
    @include bp(tablet) {
        padding: 0 rem(24);
    }
    @include bp(desktop) {
        padding: 0 rem(32);
    }
    @include bp(large) {
        max-width: rem(1320);
        margin: 0 auto;
    }
}
