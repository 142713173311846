

//STATEMENT
.is-scroll-disabled {
  overflow: hidden !important;
  position: fixed;
}

//VISIBILITY
.is-hidden {
  display: none !important;
  
  &-mobile {
    @include breakpoint(0 map-get($mq, tablet)) {
      display: none !important;
    }
  }

  &-tablet {
    @include breakpoint(map-get($mq, tablet) map-get($mq, desktop)) {
      display: none !important;
    }
    &-from {
      @include breakpoint(map-get($mq, tablet)) {
        display: none !important;
      }
    }
    &-until {
      @include breakpoint(0 map-get($mq, tablet)) {
        display: none !important;
      }
    }
  }

  &-desktop {
    @include breakpoint(map-get($mq, desktop)) {
      display: none !important;
    }
  }
  
}