@import "./rs22/_vars.scss";
@mixin square($dim) {
  width: $dim;
  height: $dim;
}
@import "./node_modules/bootstrap/scss/functions";
@import './node_modules/bootstrap/scss/mixins';
@import "./rs22/_bs-variables.scss";
@import './node_modules/bootstrap/scss/grid';
@import './node_modules/bootstrap/scss/buttons';
@import './node_modules/bootstrap/scss/transitions';
@import './node_modules/bootstrap/scss/dropdown';
@import './node_modules/bootstrap/scss/utilities';
@import "./rs22/icons";
@import './rs22/layout';

.rs22 {
  font-size: 16px;
}
